<template>
  <div id="app">

    <!-- 遮罩层 -->
    <div v-if="isMobile" class="mask">
      <div class="mask-message">
        暂未适配移动端，<br>请于电脑端使用，谢谢合作！
      </div>
    </div>
    <!-- 顶部导航栏 -->
    <el-header class="header">
      <div class="logo" @click="goToHome">
        <el-image :src="pclogo" alt="Logo" style="width: 200px; height: 50px;" />

      </div>
      <el-menu mode="horizontal" class="menu">
        <el-menu-item @click="goToHome" style="font-size:18px">分析视频</el-menu-item>
        <el-menu-item @click="goToHistory" v-if="isLoggedIn" style="font-size:18px">历史记录</el-menu-item>
        <!-- <el-menu-item v-if="isLoggedIn" style="font-size:18px">分析排行</el-menu-item> -->
      </el-menu>
      <div class="user-info" v-if="isLoggedIn">
        <span style="font-size:18px;margin-right:5px">积分：{{ userDianshu }}</span>
        <el-button @click="goToProfile" class="mine-button">个人中心</el-button>
        <el-button @click="confirmLogout" class="exit-button">退出登录</el-button>
      </div>
      <el-button v-else @click="goToLogin" class="login-button">登录</el-button>
    </el-header>

    <!-- 主体内容区 -->
    <el-main>
      <!-- 路由显示页面内容 -->
      <router-view />
    </el-main>
  </div>
</template>

<script>
import { ElMessageBox } from 'element-plus'; // 导入 MessageBox
import { mapState, mapActions } from 'vuex';
import axios from 'axios'; // 导入 axios
import config from "@/config.js"; // 导入配置文件

export default {
  data() {
    return {
      pointsInterval: null,  // 定时器句柄，用于定时刷新积分
      pclogo: null,
      isMobile: false, // 用于判断是否为移动端
      showMask: false,  // 控制遮罩层的显示
    };
  },
  computed: {
    // 从 Vuex 获取登录状态、积分和账号
    ...mapState(['isLoggedIn', 'userDianshu', 'account'])
  },
  mounted() {
    this.checkLoginStatus(); // 页面加载时检查本地存储中的用户数据
    this.fetchTitle(); // 在组件挂载时调用
    this.fetchLogo(); // 在组件挂载时调用
    this.checkDevice(); // 检查设备类型

    // 如果已登录，则立即更新积分，并启动定时器定期刷新积分（例如每60秒刷新一次）
    if (this.isLoggedIn) {
      this.updatePoints();
      this.pointsInterval = setInterval(() => {
        this.updatePoints();
      }, 100000);
    }
  },
  beforeUnmount() {
    // 组件卸载前清除定时器
    if (this.pointsInterval) {
      clearInterval(this.pointsInterval);
    }
  },
  methods: {
    ...mapActions(['login', 'logout']),

    // 判断是否为移动端
    checkDevice() {
      // 判断是否为移动端
      const userAgent = navigator.userAgent;
      this.isMobile = /Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
    },

    // 弹出移动端提示
    showMobileWarning() {
      ElMessageBox.alert('暂未适配移动端，请于电脑端使用，谢谢合作！', '提示', {
        confirmButtonText: '确定',
        type: 'warning',
      }).then(() => {
        // 关闭弹窗后可以执行的操作
      });
    },

    checkLoginStatus() {
      const user = localStorage.getItem('user');
      if (user) {
        const userData = JSON.parse(user);
        this.login(userData); // 提交用户数据到 Vuex
      }
    },

    // 获取当前运行域名
    getCurrentHost() {
      return window.location.origin; // 返回完整域名，例如 "https://example.com"
    },

    // 调用接口获取标题
    async fetchTitle() {
      try {
        const response = await axios.post(`${config.BASE_URL}/pc/api/get_agent_info.php`, {
          agent_host: this.getCurrentHost(), // 传递当前域名
        });

        if (response.data.pc_title) {
          document.title = response.data.pc_title; // 动态设置页面标题

          // 设置浏览器图标
          const link = document.querySelector('link[rel="icon"]') || document.createElement('link');
          link.rel = "icon";
          link.href = response.data.pc_ico || "@/assets/favicon.ico";  // 默认图标路径
          document.head.appendChild(link);

        }
      } catch (error) {
        console.error("获取标题失败:", error);
      }
    },

    async fetchLogo() {
      try {
        const response = await axios.post(`${config.BASE_URL}/pc/api/get_agent_info.php`, {
          agent_host: this.getCurrentHost(),
        });
        if (response.data.pc_logo) {
          // 注意要使用 this. 来更新组件数据
          this.pclogo = response.data.pc_logo;
        }
      } catch (error) {
        console.error("获取标题失败:", error);
      }
    },

    // 调用接口获取最新积分
    updatePoints() {
      if (!this.account) {
        console.error('account 值不存在，无法获取积分');
        return;
      }
      const url = `${config.BASE_URL}/pc/api/getUserPoints.php`;
      axios.post(url, { account: this.account })
        .then(response => {
          const res = response.data;
          if (res.success) {
            // 假设接口返回格式为 { success: true, data: { dianshu: 100 } }
            this.$store.commit('setUserDianshu', res.data.dianshu);
          } else {
            console.error('获取积分失败：', res.error || res.message);
          }
        })
        .catch(error => {
          console.error('调用获取积分接口错误：', error);
        });
    },

    goToHome() {
      this.$router.push({ name: 'HomePage' });
    },

    goToHistory() {
      this.$router.push({ name: 'HistoryPage' });
    },

    goToProfile() {
      this.$router.push({ name: 'ProfilePage' });
    },

    // 弹出确认框
    confirmLogout() {
      ElMessageBox.confirm(
        '确定退出登录吗？',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }
      ).then(() => {
        this.logoutHandler();
      }).catch(() => {
        console.log('用户取消退出');
      });
    },

    logoutHandler() {
      // 清除本地存储和 Vuex 状态
      localStorage.removeItem('user');
      this.$store.dispatch('logout');
      this.$router.push({ name: 'LoginPage' });
    },

    goToLogin() {
      this.$router.push({ name: 'LoginPage' });
    }
  }
};
</script>


<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-align: center;
  color: #2c3e50;
}


.mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, rgba(0, 0, 0, 0.9), rgba(12, 12, 12, 0.8));
  /* 渐变效果，深黑与流光黑的过渡 */
  z-index: 9999;
  /* 确保遮罩层在最上层 */
  display: flex;
  justify-content: center;
  align-items: center;
  animation: glow 2s infinite alternate;
  /* 添加流光动画效果 */
}

@keyframes glow {
  0% {
    background: rgba(12, 12, 12, 0.7);
  }

  100% {
    background: rgba(0, 0, 0, 0.85);
  }
}


.mask-message {
  color: white;
  font-size: 18px;
  text-align: center;
  padding: 20px;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 8px;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 20px;
  border-bottom: 2px solid #e8e8e8;
  background-color: #fff;
}

.logo {
  flex: 0 0 auto;
  margin-left: 230px;
  cursor: pointer;
}

.menu {
  flex-grow: 1;
  text-align: center;
}

.user-info {
  margin-right: 320px;
  display: flex;
  align-items: center;
  gap: 15px;
}

.el-main {
  padding: 20px;
}

.login-button {
  margin-right: 320px;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity, 1));
  color: white;
  padding: 20px 18px;
  border-radius: 5px;
  font-size: 16px;
}

.login-button:hover {
  background-color: rgb(37 99 235 / var(--tw-bg-opacity, 1));
}

.mine-button {
  padding: 20px 18px;
  border-radius: 5px;
  font-size: 16px;
}

.exit-button {
  background: linear-gradient(135deg, #6a11cb, #2575fc);
  color: white;
  padding: 20px 18px;
  border-radius: 5px;
  font-size: 16px;
}
</style>
